<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div class="tw-ending-progress-steps mt-48 sm:mt-56">
    <div
      v-for="(step, index) in steps"
      :key="`step__${index}`"
      class="tw-ending-progress-steps__step mb-20 sm:mb-24"
      :class="getStatusClass(currentPercentage, index)"
    >
      <div class="tw-ending-progress-steps__step-text">
        <span class="tw-ending-progress-steps__step-checkmark">
          <img :src="config.steps?.checkmarkUrl || getAssetFromCdn('editor/checkmark_1.png')" alt="checkmark" />
        </span>
        <span>{{ step }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { getAssetFromCdn } from '@shared/utils/helpers';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['endingprogress']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  totalTime: { type: Number, default: 0 },
  currentPercentage: { type: Number, default: 0 },
  steps: { type: Array as PropType<string[]>, default: () => [] }
});

const stepsStyle = computed(() => {
  const textStyles = props.config.steps?.text;
  return {
    text: {
      fontFamily: props.config.steps?.fontFamily || props.pageOptions.fontFamily,
      fontSize: `${props.config.steps?.fontSize || 16}px`,
      regular: textStyles?.regular || props.pageOptions.colors.text[1],
      inProgress: textStyles?.inProgress || props.pageOptions.colors.text[0],
      done: textStyles?.done || props.pageOptions.colors.text[0]
    }
  };
});

function getStatusClass(percentage: number, index: number) {
  const stepsCount = props.steps.length;
  if (!stepsCount) return;
  const percentagePerStep = 100 / stepsCount;
  const inProgressStart = index * percentagePerStep;
  const inProgressEnd = inProgressStart + percentagePerStep;
  if (percentage >= inProgressStart && percentage < inProgressEnd) {
    return 'in_progress';
  }
  if (percentage >= inProgressEnd) {
    return 'completed';
  }
}
</script>

<style lang="postcss" scoped>
.tw-ending-progress-steps {
  --step-regular-color: v-bind(stepsStyle.text.regular);
  --step-inProgress-color: v-bind(stepsStyle.text.inProgress);
  --step-done-color: v-bind(stepsStyle.text.done);
  --step-font-family: v-bind(stepsStyle.text.fontFamily);
  --step-font-size: v-bind(stepsStyle.text.fontSize);

  @apply px-20 text-center;

  &__step {
    @apply flex items-center justify-center;
    &-checkmark {
      @apply mr-16 inline-block h-24 w-24 shrink-0 translate-x-4 items-center justify-center opacity-0 transition-all duration-300;
      img {
        @apply h-24 w-24 object-contain;
      }
    }
    &-text {
      @apply flex -translate-x-20 items-center transition-all;
      word-break: break-word;
      color: var(--step-regular-color);
      font-family: var(--step-font-family);
      font-size: var(--step-font-size);
    }
    &.completed {
      .tw-ending-progress-steps__step {
        &-text {
          color: var(--step-done-color);
        }
        &-checkmark {
          @apply translate-x-0 opacity-100;
        }
      }
    }
    &.in_progress {
      .tw-ending-progress-steps__step {
        &-text {
          @apply transform !font-semibold;
          color: var(--step-inProgress-color);
        }
      }
    }
  }
}
</style>
